import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const PrivacySection= ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const openModal = (e) => {
    e.preventDefault();
  }

  const closeModal = (e) => {
    e.preventDefault();
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'hero-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container-sm">
        <div className={innerClasses}>
          <div className="hero-content">
            <h1 className="mt-0 mb-16 reveal-from-bottom" data-reveal-delay="200">
            Privacy policy and terms of service
            </h1>
            <div className="container-xs">
              <p className="m-0 mb-32 reveal-from-bottom" data-reveal-delay="400">        
                Thanks for visiting our privacy policy and terms of services page. Before we get into the details, let us say that at Blobecoin we value your Privacy. We will aim to treat your data in the same way we would expect our own personal data to be treated, in particular we have no plans to make money off selling your personal data to anyone.
                By using any of Blobecoin services (“Services”), you agree to our Privacy Policy and Terms of Use.
                If you choose to use our Services, then you will be agreeing to the collection and use of information in relation to this policy. The information that we collect is used for providing and improving our services. We will not knowingly use or share your information with anyone except as described in this policy.
                We would like to encourage you to read the whole Privacy Policy, however, we have summarised the key points below:
                We will not knowingly share your personal information with any third party without your explicit permission apart for the purposes described here.
                If at any point you no longer wish to share your data with us and wish to withdraw your data, you can notify us at any time and we will take the necessary action to ensure this happens.
                <h2>Security</h2>
                Blobecoin endeavours to protect your data. We will keep your data on secure servers, and aim to encrypt all communication to and from our servers, such that your data is reasonably safe from third party intervention. We shall take reasonable precautions to prevent unauthorised access and use of your personal data. However, there are always risks associated with providing personal data via technologies and no system is completely tamper or hacker proof, so we cannot guarantee its absolute security. We will inform you and the authorities within 72 hours of becoming aware of any such security breaches.

                <h2>Changes to This Privacy Policy</h2>
                We may update our Privacy Policy from time to time. Thus, you are advised to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately after they are posted on this page for new users and will be effective for old users within 30 days.
                <h2>Disclaimer</h2>
                Blobecoin services are provided on an “as is” basis, and no warranty, either expressed or implied is given. Your use of our services is at your risk. We do not warrant that our services will meet your specific requirement or is compatible with any other services. Moreover, we do not guarantee that the service will be completely uninterrupted, timely, secure, valid or accurate. We do not guarantee that the services will meet your expectations or that any errors reported or observed will be corrected.
                <h2>Termination of our services</h2>
                We may choose to terminate your account at any time for any reason, in particular if we believe your use of Blobecoin violates our privacy policy or terms of use. 
                <h2>Email lists and subscriptions</h2>
                If you sign up to any of our services, we will add you to our mailing lists, and we may email you from time to time with information about our services or new products and features. Any such email will generally have an unsubscribe link, however, you may also contact us. directly to make such requests.
                <h2>Governing Law and Jurisdiction</h2>
                This Agreement and any dispute or claim relating to or connected with it (including non-contractual disputes or claims) shall be governed by and construed in accordance with the law of England and Wales and the Parties submit to the exclusive jurisdiction of the High Court in London.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

PrivacySection.propTypes = propTypes;
PrivacySection.defaultProps = defaultProps;

export default PrivacySection;