import React, { useState } from 'react';
import { Document, Page, pdfjs } from "react-pdf"
// import sections
// import Cta from '../components/sections/Cta';
import SupportSection from '../components/sections/SupportSection';
// pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
import file from './blobepaper.pdf';


const Support = () => {
  // const [numPages, setNumPages] = useState(null);
  // const [pageNumber, setPageNumber] = useState(1);

  // function onDocumentLoadSuccess({ numPages }) {
  //   setNumPages(numPages);
  // }

  return (
    <> 
      {/*<div>
       <Document
        file="/blobepaper.pdf"
        onLoadSuccess={onDocumentLoadSuccess}
      >
        <Page pageNumber={pageNumber} />
      </Document>
      <p>Page {pageNumber} of {numPages}</p>
      </div> */}
      
      {/* <a href="./blobepaper.pdf" download>hi</a>
      <a href="./blobepaper.pdf" download><button className="button button-primary button-wide-mobile button-sm">Download</button></a> */}
      <SupportSection className="illustration-section-01" />
    </>
  );
}

export default Support;