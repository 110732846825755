
import { VerticalTimeline, VerticalTimelineElement }  from 'react-vertical-timeline-component';
import 'react-vertical-timeline-component/style.min.css';

import React, { useState } from 'react';
import classNames from 'classnames';
import { SectionProps } from '../../utils/SectionProps';
import ButtonGroup from '../elements/ButtonGroup';
import Button from '../elements/Button';
import Image from '../elements/Image';
import Modal from '../elements/Modal';
import SectionHeader from './partials/SectionHeader';

const propTypes = {
  ...SectionProps.types
}

const defaultProps = {
  ...SectionProps.defaults
}

const RoadMap= ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  ...props
}) => {


  const openModal = (e) => {
    e.preventDefault();
  }

  const closeModal = (e) => {
    e.preventDefault();
  }   

  const outerClasses = classNames(
    'hero section center-content',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const sectionHeader = {
    title: 'Timeline',
    paragraph: 'We locked the 50% of the total supply to PancakeSwap and burned all the liquidity token! The remaining 50% of token was burned to a dead dress. No token were allocated to a dev team prior to the launch on the open market.'
  };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <SectionHeader data={sectionHeader} className="center-content" />
    <VerticalTimeline>
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#ffa' }}
        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
        date="Q1 2021"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        // icon={<WorkIcon />}
    >
        <h3 className="vertical-timeline-element-title">Phase 0:</h3>
        <h4 className="vertical-timeline-element-subtitle">Research & Development</h4>
        <p>
        BlobeCoin formula by Sakana-san
        <br></br>
        Token Development
        <br></br>
        Social media live 
        </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        contentArrowStyle={{ borderRight: '7px solid  rgb(33, 150, 243)' }}
        date="Q2 2021"
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        // icon={<WorkIcon />}
    >
        <h3 className="vertical-timeline-element-title">Phase 1:</h3>
        <h4 className="vertical-timeline-element-subtitle">Launch</h4>
        <p>
        BlobeCoin deployed on Pancake Swap
        <br/>
        <a href="https://bscscan.com/tx/0x836e2a2ade045bfff833fe3235d7307a7b812be3821728f05c134d8962e5144c"><span className="text-color-primary"> See TX on BscScan</span></a>
        <br/>
        All Liquidity Token burned
        <br/>
        <a href="https://bscscan.com/tx/0x5be49bb680a32a3064cf047ae3f794d3c84d9bb2ead1baf6ac38595caac739eb"><span className="text-color-primary"> See TX on BscScan</span></a>
        <br/>
        2.5 Quadrillions Coins burned ( 50% supply )
        <br/>
        <a href="https://bscscan.com/tx/0xc9997fee30a8a2025fdad854a215e79fa0c3438cfd47e7a87208a276407d8db1"><span className="text-color-primary"> See TX on BscScan</span></a>
        <br/>

        
        </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="Q3 2021"
        contentStyle={{ background: 'rgb(33, 150, 243)', color: '#ffa' }}
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        // icon={<WorkIcon />}
    >
        <h3 className="vertical-timeline-element-title">Phase 2:</h3>
        <h4 className="vertical-timeline-element-subtitle">Growth of the Blobecoin community<br/>
        Listing on multiple exchanges
        </h4>
        <p>
        Marketing
        </p>
    </VerticalTimelineElement>
    {/* <VerticalTimelineElement
        className="vertical-timeline-element--work"
        date="2006 - 2008"
        iconStyle={{ background: 'rgb(33, 150, 243)', color: '#fff' }}
        // icon={<WorkIcon />}
    >
        <h3 className="vertical-timeline-element-title">Web Designer</h3>
        <h4 className="vertical-timeline-element-subtitle">San Francisco, CA</h4>
        <p>
        User Experience, Visual Design
        </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="April 2013"
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        // icon={<SchoolIcon />}
    >
        <h3 className="vertical-timeline-element-title">Content Marketing for Web, Mobile and Social Media</h3>
        <h4 className="vertical-timeline-element-subtitle">Online Course</h4>
        <p>
        Strategy, Social Media
        </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="November 2012"
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        // icon={<SchoolIcon />}
    >
        <h3 className="vertical-timeline-element-title">Agile Development Scrum Master</h3>
        <h4 className="vertical-timeline-element-subtitle">Certification</h4>
        <p>
        Creative Direction, User Experience, Visual Design
        </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        className="vertical-timeline-element--education"
        date="2002 - 2006"
        iconStyle={{ background: 'rgb(233, 30, 99)', color: '#fff' }}
        // icon={<SchoolIcon />}
    >
        <h3 className="vertical-timeline-element-title">Bachelor of Science in Interactive Digital Media Visual Imaging</h3>
        <h4 className="vertical-timeline-element-subtitle">Bachelor Degree</h4>
        <p>
        Creative Direction, Visual Design
        </p>
    </VerticalTimelineElement>
    <VerticalTimelineElement
        iconStyle={{ background: 'rgb(16, 204, 82)', color: '#fff' }}
        // icon={<StarIcon />}
    />*/}
    </VerticalTimeline> 
    </section>
  );
}

RoadMap.propTypes = propTypes;
RoadMap.defaultProps = defaultProps;

export default RoadMap;