import React from 'react';
import classNames from 'classnames';
import { SectionSplitProps } from '../../utils/SectionProps';
import SectionHeader from './partials/SectionHeader';
import Image from '../elements/Image';
import YouTube from 'react-youtube';

const propTypes = {
  ...SectionSplitProps.types
}

const defaultProps = {
  ...SectionSplitProps.defaults
}

const TutorialSplit = ({
  className,
  topOuterDivider,
  bottomOuterDivider,
  topDivider,
  bottomDivider,
  hasBgColor,
  invertColor,
  invertMobile,
  invertDesktop,
  alignTop,
  imageFill,
  ...props
}) => {

  const outerClasses = classNames(
    'features-split section',
    topOuterDivider && 'has-top-divider',
    bottomOuterDivider && 'has-bottom-divider',
    hasBgColor && 'has-bg-color',
    invertColor && 'invert-color',
    className
  );

  const innerClasses = classNames(
    'features-split-inner section-inner',
    topDivider && 'has-top-divider',
    bottomDivider && 'has-bottom-divider'
  );

  const splitClasses = classNames(
    'split-wrap',
    invertMobile && 'invert-mobile',
    invertDesktop && 'invert-desktop',
    alignTop && 'align-top'
  );

  const sectionHeader = {
    title: 'How to buy Blobecoin?',
    paragraph: 'In 5 steps'
  };

      const opts = {
      height: '396',
      width: '528',
      playerVars: {
        // https://developers.google.com/youtube/player_parameters
        autoplay: 1,
      },
    };

  return (
    <section
      {...props}
      className={outerClasses}
    >
      <div className="container">
        <div className={innerClasses}>
          <SectionHeader data={sectionHeader} className="center-content" />
          <div className={splitClasses}>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 1:
                  </div>
                <h3 className="mt-0 mb-12">
                  Get some BNB
                  </h3>
                <p className="m-0">
                Sign up for a Binance account if you haven’t already and make a deposit. 
                <br/>
                <a href="https://www.binance.com/"><span className="text-color-primary">https://www.binance.com</span></a>
                <br/>
                Exchange this balance for BNB.
                
                
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  // src={require('./../../assets/images/features-split-image-03.png')}
                  src={require('./../../assets/images/binance.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>

          <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 2:
                  </div>
                <h3 className="mt-0 mb-12">
                  Get Metamask
                  </h3>
                <p className="m-0">
                   <a href="https://metamask.io/download "><span className="text-color-primary">Metamask</span></a> is a crypto wallet that works on IOS, Android & Chrome. 
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  // src={require('./../../assets/images/features-split-image-03.png')}
                  src={require('./../../assets/images/metamask.png')}
                  alt="Features split 03"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-left" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 3: 
                  </div>
                <h3 className="mt-0 mb-12">
                Connect to the Binance Smart Chain network
                  </h3>
                <p className="m-0">
                    Open Metamask and click the coloured circle in the top-right of the window and select “Settings”. Scroll down to “Networks” and click “Add network”.
                    Next, input the following data into the fields provided:
                    <br/>
                    <br /><span className="text-color-primary">Network Name: </span><b> Smart Chain</b>
                    <br /><span className="text-color-primary">New RPC URL: </span><b> https://bsc-dataseed.binance.org/</b>
                    <br /><span className="text-color-primary">ChainID: </span><b> 56</b>
                    <br /><span className="text-color-primary">Symbol: </span><b> BNB</b>
                    <br /><span className="text-color-primary">Block Explorer URL: </span><b> https://bscscan.com  </b>
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <YouTube videoId="WMhY8Lqk80E" opts={opts} />;
              </div>
            </div>

            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 4
                  </div>
                <h3 className="mt-0 mb-12">
                Send BNB to your Metamask Wallet
                  </h3>
                <p className="m-0">
                Click the account name at the top of your Metamask window to copy your wallet address to your computer’s clipboard.
You can then send your BNB from Binance to this address. Please ensure you send the funds using BEP20
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  // src={require('./../../assets/images/features-split-image-02.png')}
                  src={require('./../../assets/images/transfer_bnb.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>


            <div className="split-item">
              <div className="split-item-content center-content-mobile reveal-from-right" data-reveal-container=".split-item">
                <div className="text-xxs text-color-primary fw-600 tt-u mb-8">
                  Step 5
                  </div>
                <h3 className="mt-0 mb-12">
                Buy on Pancakeswap
                  </h3>
                <p className="m-0">
                Connect your Metamask wallet to Pancakeswap using the link at the top-right of the page.
                <br/>
                <br/>
                Find Blobecoin on Pancakeswap by going to 
                <a href="https://exchange.pancakeswap.finance/#/swap?outputCurrency=0x58845D713d37cb867450507f63b59Fe6Bea16F2C"><span className="text-color-primary">This Link</span></a>
                



{/* Next, select BNB as your “From” token. Then click “Select a Currency” in the “To” field and paste in the Blobecoin address which is as follows:
<br/>0x58845D713d37cb867450507f63b59Fe6Bea16F2C */}
                  </p>
              </div>
              <div className={
                classNames(
                  'split-item-image center-content-mobile reveal-from-bottom',
                  imageFill && 'split-item-image-fill'
                )}
                data-reveal-container=".split-item">
                <Image
                  // src={require('./../../assets/images/features-split-image-02.png')}
                  src={require('./../../assets/images/pancake.png')}
                  alt="Features split 02"
                  width={528}
                  height={396} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

TutorialSplit.propTypes = propTypes;
TutorialSplit.defaultProps = defaultProps;

export default TutorialSplit;